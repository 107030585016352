import React, { useState, useEffect } from 'react';
import { Button, Input, Typography, Table, Tag, Spin, Card, Collapse, Toast, Space, Tabs, Banner } from '@douyinfe/semi-ui';
import { IconSearch, IconCopy, IconDownload, IconClose, IconRedo, IconStar } from '@douyinfe/semi-icons';
import { Modal, Popover, Image } from '@douyinfe/semi-ui';
import { API, timestamp2string } from '../helpers';
import { stringToColor } from '../helpers/render';
import { renderQuota } from '../helpers/render';
import { ITEMS_PER_PAGE } from '../constants';
import { useFilterContext } from '../context/Filter';
import FilterPanel from './FilterPanel';
import Papa from 'papaparse';

const { Text } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

function renderTimestamp(timestamp) {
    return timestamp2string(timestamp);
}

function renderIsStream(bool) {
    if (bool) {
        return <Tag color="blue" size="large">流</Tag>;
    } else {
        return <Tag color="purple" size="large">非流</Tag>;
    }
}

function renderUseTime(type) {
    const time = parseInt(type);
    if (time < 101) {
        return <Tag color="green" size="large"> {time} 秒 </Tag>;
    } else if (time < 300) {
        return <Tag color="orange" size="large"> {time} 秒 </Tag>;
    } else {
        return <Tag color="red" size="large"> {time} 秒 </Tag>;
    }
}

const LogsTable = () => {
    const {
        apikey,
        setAPIKey,
        buildFilterParams,
        setAvailableModels,
        resetFilters,
        filtersApplied,
        selectedModels
    } = useFilterContext();

    const [activeTabKey, setActiveTabKey] = useState('');
    const [tabData, setTabData] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeKeys, setActiveKeys] = useState([]);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
    const [baseUrl, setBaseUrl] = useState('');
    const [originalLogs, setOriginalLogs] = useState([]);
    const baseUrls = JSON.parse(process.env.REACT_APP_BASE_URL);

    useEffect(() => {
        // 默认设置第一个地址为baseUrl
        const firstKey = Object.keys(baseUrls)[0];
        console.log(`初始化Tab: ${firstKey}, URL: ${baseUrls[firstKey]}`);
        setActiveTabKey(firstKey);
        setBaseUrl(baseUrls[firstKey]);
        // 初始化时重置数据和筛选条件
        resetData(firstKey);
        resetFilters();
    }, []);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
        setBaseUrl(baseUrls[key]);
        console.log(`切换到Tab: ${key}, URL: ${baseUrls[key]}`);
        // 切换tab时重置数据和筛选条件
        resetData(key);
        resetFilters(); // 重置筛选条件，包括所选模型
    };

    const resetData = (key) => {
        setTabData((prevData) => ({
            ...prevData,
            [key]: {
                balance: 0,
                usage: 0,
                accessdate: "未知",
                logs: [],
                tokenValid: false,
            }
        }));
        setOriginalLogs([]);
    };


    // 根据筛选条件过滤日志 - 仅保留模型筛选功能
    const filterLogs = (logs) => {
        if (!logs || logs.length === 0) {
            console.log("没有日志数据可供筛选");
            return [];
        }

        // 如果没有应用筛选，返回原始日志
        if (!filtersApplied) {
            console.log("未应用筛选条件，返回所有日志");
            return logs;
        }

        // 记录筛选条件
        console.log('应用筛选条件:', {
            selectedModels: selectedModels.length > 0 ? selectedModels : "所有模型"
        });

        // 仅根据模型筛选
        let result = logs;
        if (selectedModels.length > 0) {
            result = logs.filter(log => selectedModels.includes(log.model_name));
            console.log(`模型筛选后剩余 ${result.length} 条日志`);
        }

        console.log(`筛选完成: 原始日志 ${logs.length} 条，筛选后 ${result.length} 条`);

        return result;
    };

    // 查询数据的方法
    const fetchData = async () => {
        if (apikey === '') {
            Toast.warning('请先输入令牌，再进行查询');
            return;
        }

        if (!/^sk-[a-zA-Z0-9]{48}$/.test(apikey)) {
            Toast.error('令牌格式非法！');
            return;
        }

        setLoading(true);
        let newTabData = { ...tabData[activeTabKey], balance: 0, usage: 0, accessdate: 0, logs: [], tokenValid: false };

        try {
            if (process.env.REACT_APP_SHOW_BALANCE === "true") {
                const subscription = await API.get(`${baseUrl}/v1/dashboard/billing/subscription`, {
                    headers: { Authorization: `Bearer ${apikey}` },
                });
                const subscriptionData = subscription.data;
                newTabData.balance = subscriptionData.hard_limit_usd;
                newTabData.tokenValid = true;

                let now = new Date();
                let start = new Date(now.getTime() - 100 * 24 * 3600 * 1000);
                let start_date = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
                let end_date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
                const res = await API.get(`${baseUrl}/v1/dashboard/billing/usage?start_date=${start_date}&end_date=${end_date}`, {
                    headers: { Authorization: `Bearer ${apikey}` },
                });
                newTabData.usage = res.data.total_usage / 100;
            }
        } catch (e) {
            console.log(e);
            Toast.error("令牌已用尽");
            resetData(activeTabKey);
            setLoading(false);
            return;
        }

        try {
            if (process.env.REACT_APP_SHOW_DETAIL === "true") {
                // 构建API请求URL的筛选参数
                const filterParams = buildFilterParams();
                const logUrl = `${baseUrl}/api/log/token?key=${apikey}${filterParams}`;
                console.log('查询URL:', logUrl);

                const logRes = await API.get(logUrl);
                const { success, message, data: logData } = logRes.data;

                if (success) {
                    // 保存原始日志数据
                    const reversedLogs = logData.reverse();
                    setOriginalLogs(reversedLogs);

                    // 从原始日志中提取所有可用模型
                    setAvailableModels(reversedLogs);

                    // 根据筛选条件过滤日志
                    const filteredLogs = filterLogs(reversedLogs);
                    newTabData.logs = filteredLogs;

                    // 如果有筛选条件且结果为空，显示警告
                    if (filtersApplied && filteredLogs.length === 0 && reversedLogs.length > 0) {
                        Toast.warning('筛选条件未匹配到任何记录');
                    } else if (filtersApplied) {
                        Toast.success(`筛选成功，共找到 ${filteredLogs.length} 条记录`);
                    }

                    setActiveKeys(['1', '2']);
                } else {
                    Toast.error('查询调用详情失败，请输入正确的令牌');
                }
            }
        } catch (e) {
            console.error('查询失败:', e);
            Toast.error("查询失败，请输入正确的令牌");
            resetData(activeTabKey);
            setLoading(false);
            return;
        }

        setTabData((prevData) => ({
            ...prevData,
            [activeTabKey]: newTabData,
        }));
        setLoading(false);
    };

    // 监听筛选条件变化，重新筛选日志
    useEffect(() => {
        if (originalLogs.length > 0 && filtersApplied) {
            console.log('筛选条件变化，重新筛选日志');
            const filteredLogs = filterLogs(originalLogs);

            setTabData((prevData) => {
                if (!prevData[activeTabKey]) return prevData;

                return {
                    ...prevData,
                    [activeTabKey]: {
                        ...prevData[activeTabKey],
                        logs: filteredLogs
                    }
                };
            });

            if (filteredLogs.length === 0) {
                Toast.warning('筛选条件未匹配到任何记录');
            } else {
                Toast.success(`筛选成功，共找到 ${filteredLogs.length} 条记录`);
            }
        }
    }, [selectedModels, filtersApplied, originalLogs, activeTabKey]);

    const copyText = async (text) => {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(text);
                Toast.success('已复制：' + text);
                return;
            }

            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
                textArea.remove();
                Toast.success('已复制：' + text);
            } catch (err) {
                textArea.remove();
                Modal.error({ title: '无法复制到剪贴板，请手动复制', content: text });
            }
        } catch (err) {
            Modal.error({ title: '无法复制到剪贴板，请手动复制', content: text });
        }
    };

    const columns = [
        {
            title: '时间',
            dataIndex: 'created_at',
            render: renderTimestamp,
            sorter: (a, b) => a.created_at - b.created_at,
        },
        {
            title: '模型',
            dataIndex: 'model_name',
            render: (text, record) => {
                return record.type === 0 || record.type === 2 ? (
                    <div>
                        <Tag
                            color={stringToColor(text)}
                            size="large"
                            onClick={() => copyText(text)}
                        >
                            {text}
                        </Tag>
                    </div>
                ) : null;
            },
            sorter: (a, b) => ('' + a.model_name).localeCompare(b.model_name),
        },
        {
            title: '用时',
            dataIndex: 'use_time',
            render: (text, record) => {
                return record.model_name.startsWith('mj_') ? null : (
                    <Space>
                        {renderUseTime(text)}
                        {renderIsStream(record.is_stream)}
                    </Space>
                );
            },
            sorter: (a, b) => a.use_time - b.use_time,
        },
        {
            title: '输入',
            dataIndex: 'prompt_tokens',
            render: (text, record) => {
                return record.model_name.startsWith('mj_') ? null : (
                    record.type === 0 || record.type === 2 ? <span>{text}</span> : null
                );
            },
            sorter: (a, b) => a.prompt_tokens - b.prompt_tokens,
        },
        {
            title: '输出',
            dataIndex: 'completion_tokens',
            render: (text, record) => {
                return parseInt(text) > 0 && (record.type === 0 || record.type === 2) ? (
                    <span>{text}</span>
                ) : null;
            },
            sorter: (a, b) => a.completion_tokens - b.completion_tokens,
        },
        {
            title: '花费',
            dataIndex: 'quota',
            render: (text, record) => {
                return record.type === 0 || record.type === 2 ? renderQuota(text, 6) : null;
            },
            sorter: (a, b) => a.quota - b.quota,
        },
    ];

    const copyTokenInfo = (e) => {
        e.stopPropagation();
        const activeTabData = tabData[activeTabKey] || {};
        const { balance, usage, accessdate } = activeTabData;
        const info = `令牌总额: ${balance === 100000000 ? '无限' : `${balance.toFixed(3)}`}
剩余额度: ${balance === 100000000 ? '无限制' : `${(balance - usage).toFixed(3)}`}
已用额度: ${balance === 100000000 ? '不进行计算' : `${usage.toFixed(3)}`}
有效期至: ${accessdate === 0 ? '永不过期' : renderTimestamp(accessdate)}`;
        copyText(info);
    };

    const exportCSV = (e) => {
        e.stopPropagation();
        const activeTabData = tabData[activeTabKey] || { logs: [] };
        const { logs } = activeTabData;
        const csvData = logs.map(log => ({
            '时间': renderTimestamp(log.created_at),
            '模型': log.model_name,
            '用时': log.use_time,
            '输入': log.prompt_tokens,
            '输出': log.completion_tokens,
            '花费': log.quota,
        }));
        const csvString = '\ufeff' + Papa.unparse(csvData);

        try {
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.csv';

            if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                link.target = '_blank';
                link.setAttribute('target', '_blank');
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(url), 100);
        } catch (err) {
            Toast.error('导出失败，请稍后重试');
            console.error('Export failed:', err);
        }
    };

    const activeTabData = tabData[activeTabKey] || { logs: [], balance: 0, usage: 0, accessdate: "未知", tokenValid: false };

    // 渲染筛选状态标签
    const renderFilterStatus = () => {
        if (!filtersApplied || selectedModels.length === 0) return null;

        return (
            <div style={{ marginBottom: '10px' }}>
                <Space>
                    <Tag color="orange">筛选条件:</Tag>
                    <Tag color="green" key="model-filter">
                        模型: {selectedModels.join(', ')}
                    </Tag>
                </Space>
            </div>
        );
    };

    const renderContent = () => (
        <>
            <Banner fullMode={false} type="info" bordered icon={<IconStar />} closeIcon={<IconClose />}
                title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>关于SmallAI</div>}
                description={<>
                    <div>⭐ 按量计费，无需承担额度过期或者封号风险，MySQL8.2超高并发不限速，超强自研能力，基于渠道 RPM和TPM的智能负载均衡算法，响应快速，大幅降低失败率重试率</div>
                    <div>🔑 每一笔调用的消耗明细都公开透明零误差计费（✲ 为保障数据库的快速访问，日志每月1号清理，请知悉）</div>
                    <div>⏰ 已开通7*24小时<Text link={{ href: 'https://shop.smallai.asia' }}>自助充值服务</Text> 保障您的服务不会中断 </div>
                </>}
            />
            <br />
            <Banner fullMode={false} type="danger" bordered icon={<IconRedo />} closeIcon={<IconClose />}
                title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>模型定价</div>}
                description={<>
                    <div>不知道计费标准？可以在 <Text link={{ href: 'https://smallai-pro.feishu.cn/sheets/KoCXscVXKhyXXVt60BMciMdxnxh?from=from_copylink' }}>SmallAI计费标准表</Text> 查看对应模型计费标准。</div>
                    <Popover content={<Image src='/客服微信.png' width={360} height={450} />}>
                        <Tag style={{ backgroundColor: 'rgba(var(--semi-blue-4),1)', color: 'var(--semi-color-white)' }}>联系客服</Tag>
                    </Popover>
                </>}
            />

            {/* 使用新的筛选面板组件 */}
            <FilterPanel onSearch={fetchData} />

            <Card style={{ marginTop: 24 }}>
                <Input
                    showClear
                    value={apikey}
                    onChange={setAPIKey}
                    placeholder="请输入要查询的令牌 sk-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                    prefix={<IconSearch />}
                    suffix={
                        <Button
                            type='primary'
                            theme="solid"
                            onClick={fetchData}
                            loading={loading}
                            disabled={apikey === ''}
                        >
                            查询
                        </Button>
                    }
                    onKeyDown={(e) => e.key === 'Enter' && fetchData()}
                />
            </Card>
            <Card style={{ marginTop: 24 }}>
                {renderFilterStatus()}
                <Collapse activeKey={activeKeys} onChange={setActiveKeys}>
                    {process.env.REACT_APP_SHOW_BALANCE === "true" && (
                        <Panel
                            header="令牌信息"
                            itemKey="1"
                            extra={
                                <Button icon={<IconCopy />} theme='borderless' type='primary' onClick={copyTokenInfo} disabled={!activeTabData.tokenValid}>
                                    复制令牌信息
                                </Button>
                            }
                        >
                            <Spin spinning={loading}>
                                <div style={{ marginBottom: 16 }}>
                                    <Text type="secondary">
                                        令牌总额：{activeTabData.balance === 100000000 ? "无限" : activeTabData.balance === "未知" || activeTabData.balance === undefined ? "未知" : `${activeTabData.balance.toFixed(3)}`}
                                    </Text>
                                    <br /><br />
                                    <Text type="secondary">
                                        剩余额度：{activeTabData.balance === 100000000 ? "无限制" : activeTabData.balance === "未知" || activeTabData.usage === "未知" || activeTabData.balance === undefined || activeTabData.usage === undefined ? "未知" : `${(activeTabData.balance - activeTabData.usage).toFixed(3)}`}
                                    </Text>
                                    <br /><br />
                                    <Text type="secondary">
                                        已用额度：{activeTabData.balance === 100000000 ? "不进行计算" : activeTabData.usage === "未知" || activeTabData.usage === undefined ? "未知" : `${activeTabData.usage.toFixed(3)}`}
                                    </Text>
                                    <br /><br />
                                    <Text type="secondary">
                                        有效期至：{activeTabData.accessdate === 0 ? '永不过期' : activeTabData.accessdate === "未知" ? '未知' : renderTimestamp(activeTabData.accessdate)}
                                    </Text>
                                </div>
                            </Spin>
                        </Panel>
                    )}
                    {process.env.REACT_APP_SHOW_DETAIL === "true" && (
                        <Panel
                            header={`调用详情 ${activeTabData.logs.length > 0 ? `(${activeTabData.logs.length}条记录${originalLogs.length > activeTabData.logs.length ? `，筛选自${originalLogs.length}条` : ''})` : ''}`}
                            itemKey="2"
                            extra={
                                <Button icon={<IconDownload />} theme='borderless' type='primary' onClick={exportCSV} disabled={!activeTabData.tokenValid || activeTabData.logs.length === 0}>
                                    导出为CSV文件
                                </Button>
                            }
                        >
                            <Spin spinning={loading}>
                                <Table
                                    columns={columns}
                                    dataSource={activeTabData.logs}
                                    pagination={{
                                        pageSize: pageSize,
                                        hideOnSinglePage: true,
                                        showSizeChanger: true,
                                        pageSizeOpts: [10, 20, 50, 100],
                                        onPageSizeChange: setPageSize,
                                        showTotal: (total) => `共 ${total} 条`,
                                        showQuickJumper: true,
                                        total: activeTabData.logs.length,
                                        style: { marginTop: 12 },
                                    }}
                                    empty={
                                        <div style={{ padding: '24px 0' }}>
                                            {filtersApplied ? '没有符合筛选条件的数据' : '暂无数据'}
                                        </div>
                                    }
                                />
                            </Spin>
                        </Panel>
                    )}
                </Collapse>
            </Card>
        </>
    );

    return (
        <>
            {Object.keys(baseUrls).length > 1 ? (
                <Tabs type="line" onChange={handleTabChange}>
                    {Object.entries(baseUrls).map(([key, url]) => (
                        <TabPane tab={key} itemKey={key} key={key}>
                            {renderContent()}
                        </TabPane>
                    ))}
                </Tabs>
            ) : (
                renderContent()
            )}
        </>
    );
};

export default LogsTable;
