import React from 'react';
import { Card, Space, Select, Button } from '@douyinfe/semi-ui';
import { IconFilter, IconRefresh, IconSearch } from '@douyinfe/semi-icons';
import { useFilterContext } from '../context/Filter';

/**
 * 筛选面板组件
 * 仅包含模型选择器，移除了日期筛选功能
 */
const FilterPanel = ({ onSearch }) => {
    const {
        selectedModels,
        setSelectedModels,
        availableModels,
        resetFilters,
        applyFilters
    } = useFilterContext();

    // 处理模型选择变化
    const handleModelChange = (values) => {
        console.log('选择模型:', values);
        setSelectedModels(values);
    };

    // 处理重置筛选条件
    const handleReset = () => {
        console.log('重置筛选条件');
        resetFilters();
        // 立即触发查询
        if (onSearch) onSearch();
    };

    // 处理搜索按钮点击
    const handleSearch = () => {
        console.log('应用筛选条件');
        // 应用筛选
        applyFilters();
        // 触发查询
        if (onSearch) onSearch();
    };

    return (
        <Card style={{ marginTop: 24 }}>
            <div style={{ marginBottom: 16 }}>
                <Space>
                    <Select
                        prefix={<IconFilter />}
                        placeholder="选择模型"
                        multiple
                        filter
                        showClear
                        value={selectedModels}
                        onChange={handleModelChange}
                        style={{ width: 280 }}
                        aria-label="模型选择"
                        emptyContent="请先查询数据获取可用模型"
                    >
                        {Array.from(availableModels).map(model => (
                            <Select.Option key={model} value={model}>
                                {model}
                            </Select.Option>
                        ))}
                    </Select>
                    <Button
                        type="primary"
                        icon={<IconSearch />}
                        onClick={handleSearch}
                    >
                        应用筛选
                    </Button>
                    <Button
                        type="tertiary"
                        icon={<IconRefresh />}
                        onClick={handleReset}
                    >
                        重置筛选
                    </Button>
                </Space>
            </div>
        </Card>
    );
};

export default FilterPanel;
