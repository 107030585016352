import React, { createContext, useContext, useState } from 'react';

// 创建筛选上下文
const FilterContext = createContext();

// 自定义Hook，用于访问筛选上下文
export const useFilterContext = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilterContext必须在FilterProvider内使用');
    }
    return context;
};

// 筛选上下文提供者组件
export const FilterProvider = ({ children }) => {
    const [selectedModels, setSelectedModels] = useState([]);
    const [availableModels, setAvailableModels] = useState(new Set());
    const [apikey, setAPIKey] = useState('');
    const [filtersApplied, setFiltersApplied] = useState(false);

    // 重置所有筛选条件
    const resetFilters = () => {
        setSelectedModels([]);
        setFiltersApplied(false);
    };

    // 应用筛选条件
    const applyFilters = () => {
        setFiltersApplied(true);
        console.log('应用筛选条件', {
            selectedModels
        });
    };

    // 从日志数据中提取可用模型
    const updateAvailableModels = (logs) => {
        const models = new Set();
        if (logs && logs.length > 0) {
            logs.forEach(log => {
                if (log.model_name) {
                    models.add(log.model_name);
                }
            });
        }
        setAvailableModels(models);
    };

    // 构建API请求URL的筛选参数 - 已修改为仅返回空字符串，不添加过滤参数到API请求中
    const buildFilterParams = () => {
        // 不在API请求中添加筛选条件，筛选将在前端完成
        return '';
    };

    return (
        <FilterContext.Provider
            value={{
                apikey,
                setAPIKey,
                selectedModels,
                setSelectedModels,
                availableModels,
                setAvailableModels: updateAvailableModels,
                resetFilters,
                applyFilters,
                filtersApplied,
                buildFilterParams
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export default FilterContext;
